import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import {  HttpHeaders, HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class RegistrationService {

    constructor(private http: HttpClient) { }
    getAproveduser(slotId,page, search, institution, course, degree, roll_no): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/approved-users?slotId=` + slotId + '&page=' + page + '&search=' + search + '&institution=' + institution + '&course=' + course + '&degree=' + degree + '&roll_no=' + roll_no).pipe(map(user => user));
    }

    getEmployee(slotId,page, search,): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/employee?slotId=` + slotId + '&page=' + page + '&search=' + search ).pipe(map(user => user));
    }

    getAprovedusercount(slotId,page, search, institution, course, degree, roll_no): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/approved-users-count?slotId=` + slotId + '&page=' + page + '&search=' + search + '&institution=' + institution + '&course=' + course + '&degree=' + degree + '&roll_no=' + roll_no).pipe(map(user => user));
    }

    addqrcount(id,accompany): Observable<any> {
       
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/extra-qr`,{bookingId:id,acompany:accompany}).pipe(map(user => user));
    }

    getUserphotos(page, search, institution,startdate,enddate:any): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/users-photos?page=` + page + '&search=' + search + '&institution=' + institution+ '&start_date=' + startdate+ '&end_date=' + enddate).pipe(map(user => user));
    }

    getPostalphotos(page, search, institution,startdate,enddate:any): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/postals-users-photos?page=` + page + '&search=' + search + '&institution=' + institution+ '&start_date=' + startdate+ '&end_date=' + enddate).pipe(map(user => user));
    }

    getUsercount(slotId): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/approved-users-count?slotId=` + slotId).pipe(map(user => user));
    }

    getInstitute(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/institute`).pipe(map(user => user));
    }

    getFeedback(page): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/feedback-list?page=`+page).pipe(map(user => user));
    }

    getCourse(id): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/course/institute/`+id).pipe(map(user => user));
    }

    getDegree(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/degree`).pipe(map(user => user));
    }

    getEvents(page): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/events?page=` + page).pipe(map(user => user));
    }

    downloaduser(slotId, search, institution, course, degree, roll_no): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/users-export-excel?slotId=` + slotId + '&page=' +  '&search=' + search + '&institution=' + institution + '&course=' + course + '&degree=' + degree + '&roll_no=' + roll_no, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadconvocationreport(slotId): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/reports/totalBookedStudents?slotId=` + slotId, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadinstitutereport(slotId): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/reports/inst-wise?slotId=` + slotId, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloaddegreereport(slotId): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/reports/degree-wise?slotId=` + slotId, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloademployee(slotId, search): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/employee/download?slotId=` + slotId  , { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloaImage(institution,startdate,enddate): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/users-photos-download?institution=` + institution+'&start_date='+startdate+'&end_date='+enddate  , { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadPostalImage(institution,startdate,enddate): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/postals-photos-download?institution=` + institution+'&start_date='+startdate+'&end_date='+enddate  , { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    //On Postal 
    getPostaluser(page, search, institution, course, degree, roll_no,status): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/postal-users?page=` + page + '&search=' + search + '&institution=' + institution + '&course=' + course + '&degree=' + degree + '&roll_no=' + roll_no + '&status=' + '').pipe(map(user => user));
    }

    getPostalcount( institution, course, degree): Observable<any> {
        
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/postal-users/counts?institution=`  + institution  + '&course=' + course + '&degree=' + degree ).pipe(map(user => user));
    }

    downloadPostaluser( search, institution, course, degree, roll_no): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/postal-users/export-excel?search=`  + search + '&institution=' + institution + '&course=' + course + '&degree=' + degree + '&roll_no=' + roll_no, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }
    downloadTransactionDetails( search, institution, course,status): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/transactions/download?search=`  + search + '&institute=' + institution + '&course=' + course + '&status=' + status , { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadrefundDetails( search, institution, course): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/refunds/download?search=`  + search + '&institution=' + institution + '&course=' + course  , { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    getTransaction(page, search, institution, course,status): Observable<any> {
        if (!search) {
            search = '';
        }
        if (!status) {
            status = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/transactions?page=` + page + '&search=' + search + '&institute=' + institution + '&course=' + course + '&status=' + status).pipe(map(user => user));
    }

    getTransactionamount(): Observable<any> {
        
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/transactions/totalamount`).pipe(map(user => user));
    }

    downloadTransaction(search, institution, course,status): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/users-export-excel?search=` + search + '&institute=' + institution + '&course=' + course, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    getRefund(page, search, institution, course,status): Observable<any> {
        if (!search) {
            search = '';
        }
        if (!status) {
            status = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/refunds?page=` + page + '&search=' + search + '&institution=' + institution + '&course=' + course + '&status=' + status).pipe(map(user => user));
    }

    updateRefund(id:any,status: any) {
        var formData = {
            'status': status
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/refunds/update/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    deleteRefund(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/refunds/delete/` + id).pipe(map(user => {
            return user;
        }));
    }



}
